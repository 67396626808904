.mySwiper {
    width: 90%;
    height: 80vh;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
 }
  
.swiper-slide {
  text-align: center;
  background: transparent;
  
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
  
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  z-index: -1;
}
@media screen and (max-width: 650px){ 
  .mySwiper {
    width: 85%;
    height: 50vh;
  }
}

@media screen and (max-width: 900px){ 
  .mySwiper {
    width: 85%;
    height: 50vh;
  }
}