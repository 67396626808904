@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");


#app { height: 100% }
html,
* {
  font-family: Arial, Helvetica, sans-serif, Roboto;
}
#root {
  width: 100%;
  height: 100vh;
  display: flexbox;
}

body {
  width: 100%;
  height: fit-content;
  background: #28282b;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 14px;
  color:#fff;
  margin: 0;
  padding: 0;
  flex: 1;
  
}
.App {
  overflow-x: hidden;
}

#app__backgroundVideo{
  width: 100%;
  height: auto;
  float: left;
  top: 0;
  left: 0;
  padding: none;
  position: relative;
  margin-bottom: 100px;
  -o-object-fit: cover;
    object-fit: cover;
}

.scrollTop {
  position: fixed; 
  width: 100%;
  bottom: 20px;
  height: 20px;

  z-index: 1;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover{
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.bottom-bar{
  width: 100%;
  height: 220px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 100px;  
  bottom: 0;
  left: 0;
  background: #28282b;
}

.topbar {
  width: 100%;
  height: 10vh;
  justify-content: center;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
}


.topbar.active { 
  background: chocolate;
}

.about-image {
  position: relative;
  max-width: 80%;
  height: auto;
  left: 10%;
  background-image: url('./components/imagegallery/2.jpg');
  background-repeat: no-repeat;
}

.fotoShopee{
  width: 400px; 
  height: 99px;
}

@media screen and (max-width: 650px){
  .bottom-bar {
    height: fit-content;
  }
  .topbar {
    display: none;
    height: 6vh;
  }
  .fotoShopee{
    margin-top: 100px;
  }
}
@media screen and (max-width: 900px){
  .bottom-bar {
    height: fit-content;
  }
  .topbar {
    height: 6vh;
  }
}

